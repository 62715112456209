@import "../../../styles/_vars"

.OutlineContainer
    width: 95%
    display: flex
    align-items: center
    justify-content: center
    margin-bottom: 20px
    background-color: #F1F5FB
    border-radius: 4px
    padding: 20px 0

.Container
    width: 95%
    display: flex
    align-items: center
    justify-content: space-between
    font-size: 20px
    font-weight: bold
    flex-wrap: wrap
    gap: 40px
    @media screen and (max-width: $end_tablet_width)
        gap: 10px

.Title

.ToCenterInput
    display: flex
    align-items: center
    justify-content: center
    width: 100%

.Input
    height: 50px
    width: 180px
    border: solid 1px #E3E3E6
    border-radius: 8px
    font-size: 18px
    padding: 0 10px
    font-family: 'Raleway', sans-serif

.Anchor
    height: 0px

.InputStub
    position: relative
    background-color: white
    top: 7px
    left: 10px
    width: 120px
    height: 40px
    font-weight: normal
    color: #70767E
    font-size: 18px
    display: flex
    align-items: center

.InputsContainer
    display: flex
    align-items: center
    justify-content: left
    gap: 20px
    flex-wrap: wrap

.InputContainer
    display: flex
    flex-direction: column

.TextInput
    @extend .Input
    width: 240px

.BarrierInput
    @extend .Input
    width: 240px

.Arrow
    width: 35px
    height: 20px

.FieldsContainer
    display: flex
    align-items: center
    @media screen and (max-width: $end_mobile_width)
        flex-direction: column

.ValidationErrorField
    width: 100%
    height: 10px
    font-size: 10px
    color: red

.FieldsWithError
    display: flex
    flex-direction: column
    align-items: center
    justify-content: flex-start
