@import "../../../../styles/_vars"

.Container
    height: 74px
    width: 95%
    display: flex
    align-items: center
    justify-content: space-between
    border: solid 1px #E7EBEE
    animation: showDiv 1s forwards
    &:hover
        box-shadow: 0px 5px 9px 0px rgba(14, 32, 81, 0.2)
    @media screen and (max-width: $end_tablet_width)
        width: 300px
        height: 350px
        flex-direction: column
        justify-content: center
        align-items: center
        gap: 10px

.ParkingId
    font-weight: bold
    font-size: 16px
    display: flex
    align-items: center
    width: 100px
    @media screen and (max-width: $end_tablet_width)
        width: unset

.Cost
    display: flex
    align-items: center
    font-size: 16px
    gap: 10px
    width: 150px
    justify-content: center

.CostValue
    font-weight: bold
    font-size: 16px

.Address
    font-size: 16px
    gap: 10px
    min-height: 60px
    width: 250px
    height: 60px
    max-height: 60px
    display: flex
    align-items: center
    white-space: pre-wrap
    overflow: hidden
    @media screen and (max-width: $end_tablet_width)
        width: unset

.AddressText
    display: flex
    align-items: flex-start
    height: max-content
    max-height: 60px


.Capacity
    font-size: 16px
    font-weight: bold
    display: flex
    align-items: center
    gap: 5px
    width: 70px

.InfoWrapper
    display: flex
    gap: 20px
    width: 95%
    margin-left: 20px
    align-items: center
    @media screen and (max-width: $end_tablet_width)
        flex-direction: column
        gap: 10px
        margin-left: unset
        width: 80%
        gap: 5px
        align-items: left

.StatusAndButtonsWrapper
    display: flex
    gap: 10px
    align-items: center
    justify-content: center
    margin-right: 20px
    @media screen and (max-width: $end_mobile_width)
        margin-right: unset

.Info
    display: flex
    align-items: center
    justify-content: center
    min-width: 100px
    justify-content: space-between
    gap: 20px
    @media screen and (max-width: $end_tablet_width)
        width: unset

.NavIcon
    width: 20px
    height: 20px

.Rur
    font-size: 14px

.TimeToGoOut
    font-size: 16px
    font-weight: bold
    display: flex
    align-items: center
    gap: 5px
    border: solid 1px #D1D4D7
    border-radius: 5px
    height: 29px
    padding-left: 10px
    padding-right: 10px
    width: 150px

.GoOut
    font-size: 16px
    font-weight: normal
    display: flex
    align-items: center

.Number
    font-size: 16px
    font-weight: bold
    display: flex
    align-items: center
    gap: 5px

.Name
    font-size: 16px
    font-weight: normal
    display: flex
    align-items: center

.VerticalLine
    border-left: solid 1px #D1D4D7
    height: 30px

.Active
    display: flex
    margin-right: 10px

.PerMinuteTimeToExit
    display: flex
    gap: 3px
    flex-direction: column
