@import "../../../styles/_vars"

.Container
    cursor: copy

.PopupAnchor
    position: absolute
    height: 0
    width: 0

.PopUp
    position: relative
    top: -35px
    left: 0px
    color: grey
    font-size: 10px
    visibility: hidden

.PopupCopied
    @extend .PopUp
    visibility: visible
    animation: showDiv 1s forwards

.PopupInfo
    // padding: 10px
