@import "../../../styles/_vars"

.Container
    min-width: 320px
    background-color: #EFF0F2
    display: flex
    flex-direction: column
    align-items: center
    min-height: 100vh
    @media screen and (max-width: $end_tablet_width)
        display: none

.ContainerMobile
    top: 0
    left: 0
    height: 100vh
    position: fixed
    min-width: 320px
    background-color: #EFF0F2
    display: flex
    flex-direction: column
    align-items: center
    z-index: 10
    overflow-y: auto
    &::-webkit-bar
        height: 3px
        background-color: #CFD6DA
    &::-webkit-bar-thumb
        height: 3px
        background-color: #B5BEC5
        border-radius: 3px 3px 3px 3px

.InMenu
    margin-top: 120px

.InMenuMobile
    margin-top: unset

.LogoAndClose
    display: flex
    width: 90%
    justify-content: space-between
    align-items: center
    height: 50px
    @media screen and (min-width: $start_desktop_width)
        display: none
    @media screen and (min-width: $start_tablet_width) and  (max-width: $end_tablet_width)
        height: 70px

.CloseIcon
    cursor: pointer
    position: relative
    left: 25px

.Logo
    @media screen and (max-width: $end_mobile_width)
        height: 32px
        width: 32px

.LogOut
    font-size: 16px
    font-weight: 600
    color: #0F0F1E
    width: 250px
    height: 55px
    min-height: 55px
    display: flex
    align-items: center
    justify-content: space-between
    border-radius: 100px
    padding-left: 20px
    cursor: pointer
    animation: showDiv 1s forwards
