.Container
.Container100
    width: 100%
    display: flex
    justify-content: center
    display: flex
    flex-direction: column

.SelectContainer
    width: 265px
    height: 30px
    border: solid 1px #E3E3E6
    border-radius: 8px
    display: flex
    align-items: center
    justify-content: space-between
    padding: 10px
    cursor: pointer
    background-color: #FFFFFF
    font-size: 18px
    font-weight: normal
    color: #70767E

.SelectContainerRequired
    @extend .SelectContainer
    border-left-color: red

.SelectContainer100
    @extend .SelectContainer
    width: 95%

.SelectContainerRequired100
    @extend .SelectContainer100
    border-left-color: red

.Caret
    width: 20px
    height: 20px
.CaretUp
    @extend .Caret
    transform: rotate(180deg)
.ExpandedList
    @extend .SelectContainer
    height: 200px
    position: absolute
    background-color: white
    margin-top: 5px
    overflow-y: scroll
    overflow-x: hidden
    flex-direction: column
    gap: 5px
    justify-content: unset
    cursor: unset
    z-index: 1
    align-items: flex-end
.FilterPlaceHolder
    white-space: nowrap
    overflow-x: hidden
    width: 200px
.AllContainer
    width: 100%
    text-align: left
    display: flex
    gap: 10px
    align-items: center
    cursor: pointer
.UnCheckedContainer
    height: 24px
    width: 24px
    border-radius: 6px
    border: solid 1px #E3E3E6
    display: flex
    align-items: center
    justify-content: center

.CheckedContainer
    @extend .UnCheckedContainer
    background-color: #1C86F1

.Label
    font-weight: 600
    font-size: 18px

.CloseIcon
    position: fixed
    cursor: pointer

.ValidationErrorField
    width: 100%
    height: 10px
    font-size: 10px
    color: red
