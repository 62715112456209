@import "../../../styles/_vars"

.Container
  margin-top: 100px
  display: flex
  flex-direction: column
  align-items: center
  width: 95%
  @media screen and (max-width: $end_mobile_width)
    width: 100%

.ListContainer
  display: flex
  flex-direction: column
  gap: 10px
  align-items: center
  width: 95%
  height: 740px
  overflow-y: auto
  &::-webkit-bar
    height: 3px
    background-color: #CFD6DA
  &::-webkit-bar-thumb
    height: 3px
    background-color: #B5BEC5
    border-radius: 3px 3px 3px 3px

  @media screen and (max-width: $end_tablet_width)
    flex-direction: row
    flex-wrap: wrap
    justify-content: center
    align-items: unset
    margin-top: 20px

.HeaderAndButtonWrapper
  display: flex
  align-items: center
  justify-content: space-between
  width: 95%
  flex-wrap: wrap
  @media screen and (max-width: $end_mobile_width)
    flex-direction: column
    align-items: flex-start
    justify-content: unset

.GroupAction
  display: flex
  display: flex
  gap: 10px
  align-items: center
  width: 260px

.SelectedCount
  font-size: 20px
  color: #1D7DDF

.GroupPointer
  cursor: pointer

.ResetContainerAnchor
  width: 0px
  height: 0px

.ResetContainer
  width: 257px
  height: 90px
  background-color: white
  border: solid 1px #E7EBEE
  border-radius: 12px
  position: relative
  right: 110px
  top: 30px
  display: flex
  align-items: center
  justify-content: center

.Message
  font-size: 16px
  height: 400px
  display: flex
  align-items: center
  justify-content: center
  width: 300px
  text-align: center

.BookmarksAndAdd
  display: flex
  gap: 30px
  @media screen and (max-width: $end_mobile_width)
    flex-direction: column
    gap: 15px
    margin-bottom: 10px
