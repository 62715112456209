@import "../../../styles/_vars"

.Container
    width: 100%
    height: 90px
    min-height: 90px
    box-shadow: 0px 1px 4px 0 rgba(6,34,59,0.12)
    display: flex
    align-items: center
    justify-content: center
    position: absolute
    background-color: white
    @media screen and (max-width: $end_mobile_width)
        min-height: 70px


.BurgerIcon
    display: none
    margin-left: 10px
    cursor: pointer
    @media screen and (max-width: $end_tablet_width)
        display: block

.LeftSideContainer
    width: 500px
    display: flex
    align-items: center
    gap: 20px
    @media screen and (min-width: $start_desktop_width)
        gap: 10px

.Logo
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer

.LogoIcon
    @media screen and (max-width: $end_tablet_width)
        width: 32px
        height: 32px

.TextLogo
    font-size: 24px
    font-weight: bold
    display: none
    @media screen and (min-width: $start_desktop_width)
        display: block
        font-size: 15px

.Title
    font-size: 16px
    color: #70767E
    width: 450px
    @media screen and (max-width: $end_tablet_width)
        display: none

.InBarWrapper
    width: 95%
    display: flex
    align-items: center
    justify-content: space-between

.AdvLoginButtonContainer
    width: 102px
