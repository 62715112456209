@import "../../../../../styles/_vars"

.Container
    width: 400px
    height: 50px
    display: flex
    align-items: center
    justify-content: left
    gap: 20px
    cursor: pointer
    @media screen and (max-width: $end_tablet_width)
        width: unset
        margin-right: 30px
        position: relative
        left: 40px

.AvatarImage
    height: 50px
    width: 50px
    border-radius: 50%
    display: flex
    align-items: center
    justify-content: center
    font-size: 30px
    font-weight: 600
    background-color: #1C86F1
    color: white
    @media screen and (max-width: $end_mobile_width)
        width: 32px
        min-width: 32px
        height: 32px
        font-size: 16px

.InfoBlock
    display: flex
    flex-direction: column

.UserFio
    font-weight: 600
    font-size: 18px
    @media screen and (max-width: $end_tablet_width)
        display: none

.Role
    font-size: 16px
    color: #70767E
    @media screen and (max-width: $end_tablet_width)
        display: none
