@import "../../styles/_vars"

.Container
    display: flex
    flex-direction: column
    align-items: center
    width: 90%
    margin-top: 100px

.ListContainer
    display: flex
    flex-wrap: wrap
    justify-content: center
    align-items: flex-start
    gap: 10px
    width: 95%
    height: 740px
    overflow-y: auto

    &::-webkit-bar
        height: 3px
        background-color: #CFD6DA
    &::-webkit-bar-thumb
        height: 3px
        background-color: #B5BEC5
        border-radius: 3px 3px 3px 3px
    @media screen  and (max-width: $end_mobile_width)
        width: 100%

.HeaderWrapper
    display: flex
    align-items: center
    justify-content: space-between
    width: 95%
    @media screen and (max-width: $end_mobile_width)
        flex-direction: column

.Message
    font-size: 16px
    height: 400px
    display: flex
    align-items: center
    justify-content: center
    width: 300px
    text-align: center
