@import "../../styles/_vars"

.Container
    margin-top: 50px
    display: flex
    flex-direction: column
    align-items: center
    width: 95%
    @media screen and (max-width: $end_mobile_width)
        width: 100%

.ListContainer
    display: flex
    flex-direction: column
    width: 95%
    height: 740px
    overflow-y: auto
    gap: 10px
    align-items: center
    &::-webkit-bar
        height: 3px
        background-color: #CFD6DA
    &::-webkit-bar-thumb
        height: 3px
        background-color: #B5BEC5
        border-radius: 3px 3px 3px 3px
    @media screen and (max-width: $end_tablet_width)
        flex-direction: row
        flex-wrap: wrap
        justify-content: center

.HeaderAndButtonWrapper
    display: flex
    align-items: center
    justify-content: space-between
    width: 95%
    @media screen and (max-width: $end_mobile_width)
        flex-direction: column
        align-items: flex-start
        justify-content: unset

.BookmarksAndAdd
    display: flex
    gap: 30px
    @media screen and (max-width: $end_mobile_width)
        flex-direction: column
        gap: 15px
        margin-bottom: 10px

.Message
    font-size: 16px
    height: 400px
    display: flex
    align-items: center
    justify-content: center
    width: 300px
    text-align: center
